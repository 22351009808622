// extracted by mini-css-extract-plugin
export var blogContent = "carousel-module--blogContent--d11ef";
export var blogHeader = "carousel-module--blogHeader--9e996";
export var blogSubHeader = "carousel-module--blogSubHeader--9def8";
export var blogText = "carousel-module--blogText--3b6aa";
export var buttonBack = "carousel-module--buttonBack--1b90a";
export var buttonNext = "carousel-module--buttonNext--3822f";
export var card = "carousel-module--card--b801f";
export var categoryContent = "carousel-module--categoryContent--60f99";
export var categoryHeader = "carousel-module--categoryHeader--2562b";
export var centerText = "carousel-module--centerText--37056";
export var container = "carousel-module--container--07605";
export var header = "carousel-module--header--d04f3";
export var iconButton = "carousel-module--iconButton--4afa0";
export var justImage = "carousel-module--justImage--11ab6";
export var meta = "carousel-module--meta--44caf";
export var msg = "carousel-module--msg--02fb4";
export var navButton = "carousel-module--navButton--11820";
export var productHeader = "carousel-module--productHeader--e49eb";
export var slider = "carousel-module--slider--e855f";
export var text = "carousel-module--text--42f20";
export var warningMessage = "carousel-module--warningMessage--58ef5";