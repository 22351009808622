import React, { useState, useEffect } from 'react'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import {
	Container,
} from 'semantic-ui-react'

import MyAccountHeader from '../components/Pages/MyAccount/header'
import Carousel from '../components/Carousel'

import * as styles from './styles/pages.module.css'

const Negosyo = require('../../lib/negosyo')


const MyAccountPage = ({location}) => {
	const [loading, setLoading] = useState(true);

	const [ordereditemlist, setOrdereditemlist] = useState([]);

	useEffect(() => {
		Negosyo.getProductSearch("newitems")
		.then((response)=> {
			setOrdereditemlist(response);
		}).catch((err)=>{
		})
/*
		var locationkey=location.key;
		if (location.state) {
			if (location.state.customKey) {
				locationkey = location.state.customKey;
			}
		}

		Negosyo.isLoggedIn()
		.then((data)=>{
			if(data!==false) {
				/*
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					setUser(user);
					Negosyo.getUserProfile(user.username).then((response)=>{
						if(response.status==="OK") {
							setDetails(response.data);
						}
					}).catch((err)=>{
						//console.log(err)
					});
				}).catch((err)=>{
				});
				Negosyo.getProductSearch("newitems")
				.then((response)=> {
					setOrdereditemlist(response);
				}).catch((err)=>{
				})
			}
		})
*/

	}, [])

	function handleContentReload({type, url, errorVar, itemVar, loadingVar})
	{
		/*
		this.setState({[loadingVar]:true, [errorVar]:false}, ()=>{
			if(type==="category" || type==="business") {
				Negosyo.getCategorySearch(url)
				.then((response)=> {
					this.setState({[itemVar]:response, [loadingVar]:false})
				}).catch((err)=>{
					this.setState({[loadingVar]:false, [errorVar]:true})
				})
			}else {
				Negosyo.getProductSearch(url)
				.then((response)=> {
					this.setState({[itemVar]:response, [loadingVar]:false})
				}).catch((err)=>{
					this.setState({[loadingVar]:false, [errorVar]:true})
				})
			}
		})
		*/

	}
	return (
		<Layout location={location}>
			<Seo title={"My Account"} meta={[{name: `robots`, content: `noindex`}]} />
			<Container className={styles.container}>
				<React.Fragment>
					<MyAccountHeader location={location} />
					{ordereditemlist.length > 0 &&
						<Carousel
							items={ordereditemlist}
							shown={5}
							header={"Items for You"}
							type={"product"}
							dynamicLoaded={loading}
							itemVar={"newItemsCarousel"}
							loadingVar={"loadingNew"}
							errorVar={"errorNew"}
							url={"newitems"}
							error={false}
							retryFunc={handleContentReload}
							listName={`Home-New Items`}
						/>
					}
				</React.Fragment>
			</Container>
		</Layout>
	)
}

export default MyAccountPage
