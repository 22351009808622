import React, { useState, useEffect } from 'react'
import {navigate} from 'gatsby'

import {
	Header,
	Grid,
	Menu,
} from 'semantic-ui-react'

import * as styles from '../../../pages/styles/pages.module.css'

import imgiconhome from '../../../images/my-account/home.svg'
import imgiconhomeactive from '../../../images/my-account/home-active.svg'
import imgiconinfo from '../../../images/my-account/info.svg'
import imgiconinfoactive from '../../../images/my-account/info-active.svg'
import imgiconaddress from '../../../images/my-account/address.svg'
import imgiconaddressactive from '../../../images/my-account/address-active.svg'
import imgiconpassword from '../../../images/my-account/password.svg'
import imgiconpasswordactive from '../../../images/my-account/password-active.svg'

import imgiconorder from '../../../images/my-account/order.svg'
import imgiconorderactive from '../../../images/my-account/order-active.svg'

import imgiconordercomplete from '../../../images/my-account/ordercomplete.svg'
import imgiconordercompleteactive from '../../../images/my-account/ordercomplete-active.svg'

import imgiconordercancel from '../../../images/my-account/cancel.svg'
import imgiconordercancelactive from '../../../images/my-account/cancel-active.svg'

import imgiconorderpend from '../../../images/my-account/ongoing.svg'
import imgiconorderpendactive from '../../../images/my-account/ongoing-active.svg'


import imgiconsignout from '../../../images/my-account/signout.svg'

const Negosyo = require('../../../../lib/negosyo')


const MyAccountHeaderComponent = ({location})=> {
	const [user, setUser] = useState(undefined);
	const [details, setDetails] = useState({"name":""});
	const [activename, setActivename] = useState("");
	const [menutype, setMenutype] = useState("profile");

	useEffect(() => {
		var locationkey=location.key;
		var pathlist = location.pathname.split("/");
		var pathname = "";
		var tmpmenutype = "profile";

		if (location.state) {
			if (location.state.customKey) {
				locationkey = location.state.customKey;
			}
		}
		if (pathlist.length > 1) {
			pathname = pathlist[1];
			if (pathname === "my-account") {
				if (pathlist.length > 2) {
					pathname = pathlist[2];
				} else {
					pathname = "";
				}
			}
			if (pathname === "orders") {
				tmpmenutype = pathname;
				if (pathlist.length > 3) {
					pathname = pathname+"/"+pathlist[3];
				}
			}
		}
		setMenutype(tmpmenutype);
		setActivename(pathname);
		// Handles redirection if not logged in
		Negosyo.isLoggedIn()
		.then((data)=>{
			if(data===false) {
				navigate(
					`/sign-in/`,
					{
						replace:true,
						state: {
							customKey:locationkey
						}
					}
				)
			} else {
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					setUser(user);
					Negosyo.getUserProfile(user.username).then((response)=>{
						if(response.status==="OK") {
							setDetails(response.data);
						}
					}).catch((err)=>{
						//console.log(err)
					});
				}).catch((err)=>{
				});
			}
		})

	}, [])


	function handleNavigate(e, {name})
	{
		navigate(`/my-account/${name}`)
	}


	function signOut()
	{
		//custom key is for when the navigate's target url has a backButton so the new page inherits the location.key for the site mobileHeader backButton
		let customKey
		if(location.state) {
			if(location.state.customKey) {
				customKey=location.state.customKey
			}else {
				customKey=location.key
			}
		}else {
			customKey=location.key
		}
		Negosyo.logOut()
		.then(()=>{
			navigate(
				"/",
				{
					replace:true,
					state:{
						customKey:customKey
					}
				}
			)
		}).catch((err)=>{

		})
	}

	function generateMenuItem(linkname, title, imgdefault, imgactive) {
		//console.log(activename, linkname);
		return <Menu.Item
					name={linkname}
					onClick={handleNavigate}
					className={activename===linkname?styles.menuItem+" "+styles.menuItemActive:styles.menuItem}
				>
					<img src={activename===linkname?imgactive:imgdefault} alt={title} />
					{title}
				</Menu.Item>
	}

	return (
		<>
			{user &&
				<React.Fragment>
					<Header className={styles.superHeader}>Hi {details.name}</Header>
					<span id="section-top" className={styles.anchor}/>
					{menutype !== "orders" &&
						<Grid>
							<Grid.Column className={styles.gridMenu}>
								<Menu icon="labeled" secondary>
									{menutype === "profile" && <>
										{generateMenuItem("", "Home", imgiconhome, imgiconhomeactive)}
										{generateMenuItem("contact", "Information", imgiconinfo, imgiconinfoactive)}
										{generateMenuItem("address", "Addresses", imgiconaddress, imgiconaddressactive)}
										{generateMenuItem("password", "Password", imgiconpassword, imgiconpasswordactive)}
									</>}
									{menutype === "orders" && <>
										{generateMenuItem("orders", "Orders", imgiconorder, imgiconorderactive)}
										{generateMenuItem("orders/ongoing", "Ongoing", imgiconorderpend, imgiconorderpendactive)}
										{generateMenuItem("orders/completed", "Completed", imgiconordercomplete, imgiconordercompleteactive)}
										{generateMenuItem("orders/cancel", "Cancelled", imgiconordercancel, imgiconordercancelactive)}
									</>}
									{false &&
										<Menu.Item
											name="Sign Out"
											onClick={signOut}
											className={styles.menuItem}
										>
											<img src={imgiconsignout} alt="Sign Out" />
											Sign Out
										</Menu.Item>
									}

								</Menu>
							</Grid.Column>
						</Grid>
					}
				</React.Fragment>
			}
		</>
	)
}

export default MyAccountHeaderComponent

